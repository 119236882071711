import React from 'react';
import { PiInfo } from 'react-icons/pi';
import { Button } from 'src/shared/ui/button';

import PopperWrapper from 'common/PopperWrapper';
import HelpPopover from 'components/Dashboard/HelpButton/HelpPopover';
import { cookie3Analytics } from 'lib/cookie3-analytics';

export default function HelpButton() {
  return (
    <PopperWrapper
      sx={{
        zIndex: 99999,
      }}
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ],
      }}
      placement="bottom-end"
      content={<HelpPopover />}
    >
      {({ isOpen }) => (
        <Button
          onClick={() => {
            cookie3Analytics.trackEvent({
              category: 'action',
              action: 'open help',
            });
          }}
          intent="neutral"
          size="xs"
        >
          Help <PiInfo className="mt-0.5 text-xs" />
        </Button>
      )}
    </PopperWrapper>
  );
}
