import React from 'react';
import { PiList } from 'react-icons/pi';
import { Box } from '@mui/material';

import UnstyledLink from 'common/UnstyledLink';
import Button from 'components/primitives/Button';
import HStack from 'components/primitives/HStack';
import TeamDropdown from 'components/team-management/TeamDropdown';
import Cookie3Logo from 'theme/logo';
import { useGetTeams } from 'services/teamManagement/useGetTeams';

import TeamDropdownTrigger from '../TeamDropdownTrigger';

interface Props {
  onBurgerMenuClick: () => void;
  shouldShowTeamChooser?: boolean;
}
export default function MobileHeader({
  shouldShowTeamChooser = true,
  onBurgerMenuClick,
}: Props) {
  const { selectedTeam } = useGetTeams();

  return (
    <div className="sticky top-0 z-[100] h-12 border-b border-ds-border-primary bg-white/50 py-2 backdrop-blur-lg lg:hidden">
      <HStack vcenter px={1} gap={2} justifyContent={'space-between'}>
        <UnstyledLink href="/">
          <Box width="70px" height="20px">
            <Cookie3Logo />
          </Box>
        </UnstyledLink>
        <div className="flex items-center gap-2">
          {shouldShowTeamChooser && (
            <TeamDropdown
              TeamDetailsPopperBox={
                <TeamDropdownTrigger selectedTeam={selectedTeam} />
              }
            />
          )}
          <Button
            onClick={onBurgerMenuClick}
            variant="text"
            sx={{ py: 0, px: 0, color: 'text.secondary' }}
          >
            <Box>
              <PiList color="black" size="24px" />
            </Box>
          </Button>
        </div>
      </HStack>
    </div>
  );
}
