import { PropsWithChildren, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Box } from '@mui/material';
import { StepType, TourProvider, useTour } from '@reactour/tour';

import OnboardingStepCustomContent from '../CustomContent';

interface Props {
  condition?: boolean;
  onEnd?: () => void;
  storageKey: string;
  steps: StepType[];
  shouldPersist?: boolean;
}

function useOnboarding() {
  const tour = useTour();

  return tour;
}

export default function OnboardingWrapper({
  condition,
  onEnd,
  storageKey,
  steps,
  shouldPersist = true,
  children,
}: PropsWithChildren<Props>) {
  const router = useRouter();
  const tour = useTour();

  const [isFirstTime, setIsFirstTime] = useState(false);

  useEffect(() => {
    if (
      // disable onboarding for now!
      condition &&
      !router.query.register &&
      !router.query['customer-success-modal'] &&
      !router.query.login &&
      !router.query['manage']
    ) {
      const storage = localStorage.getItem(storageKey);

      if (storage === null) {
        setIsFirstTime(true);
      }
    }
  }, [router, condition, storageKey, tour]);

  const onOnboardingEnd = () => {
    onEnd?.();
    shouldPersist && localStorage?.setItem(storageKey, 'completed');
  };

  if (!isFirstTime) {
    return <>{children}</>;
  }

  return (
    <TourProvider
      beforeClose={onOnboardingEnd}
      onClickClose={() => {
        onEnd?.();
        setIsFirstTime(false);
      }}
      defaultOpen={isFirstTime}
      steps={steps}
      ContentComponent={(props) => {
        return <OnboardingStepCustomContent {...props} />;
      }}
    >
      <Box>{children}</Box>
    </TourProvider>
  );
}
