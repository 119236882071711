import { useIsLoggedIn } from 'src/store/AppProvider';
import { useTeamStore } from 'src/store/team/teamStore';
import { cn } from 'src/utils';

import TeamDropdown from 'components/team-management/TeamDropdown';
import { useGetTeams } from 'services/teamManagement/useGetTeams';

import FeedbackButton from '../FeedbackButton';
import GoToPricingButton from '../GoToPricingButton';
import DesktopHeaderDemoTeamOverlay from '../HeaderDemoTeamOverlay/desktop';
import MobileHeaderDemoTeamOverlay from '../HeaderDemoTeamOverlay/mobile';
import HelpButton from '../HelpButton';
import TeamDropdownTrigger from '../TeamDropdownTrigger';
import TokenSearch from '../TokenSearch';

interface Props {
  shouldShowTeamChooser?: boolean;
}

export default function Navigation({ shouldShowTeamChooser = true }: Props) {
  const { selectedTeam } = useGetTeams();
  const isLoggedIn = useIsLoggedIn();

  return (
    <div className="sticky top-0 z-20 max-lg:hidden">
      <div
        className={cn(
          'relative top-0 z-20 flex h-12 w-full items-center justify-between border-b border-ds-border-primary bg-ds-primary lg:sticky',
          selectedTeam?.isDemo && 'bg-ds-alert-error-bg',
        )}
      >
        {selectedTeam?.isDemo && <DesktopHeaderDemoTeamOverlay />}
        <div className="sticky flex h-full items-center justify-between px-2 lg:w-full lg:px-2">
          <div className=" hidden items-center gap-2 lg:flex">
            {shouldShowTeamChooser && (
              <TeamDropdown
                TeamDetailsPopperBox={
                  <TeamDropdownTrigger selectedTeam={selectedTeam} />
                }
              />
            )}
            {/* {isLoggedIn && <UpgradePlanButton />} */}
          </div>
          <div className="flex items-center gap-1 lg:gap-2">
            {isLoggedIn && (
              <div className="hidden sm:block">
                <TokenSearch />
              </div>
            )}
            <HelpButton />
            <FeedbackButton />
            <GoToPricingButton />
          </div>
        </div>
      </div>
      {selectedTeam?.isDemo && <MobileHeaderDemoTeamOverlay />}
    </div>
  );
}
