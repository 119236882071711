import dayjs from 'dayjs';
import { create, StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';

import { TopKolsTableParams } from 'services/twitterExplorer/analytics/useTopKolsTable';
import { ActivityRange } from 'types/Filters';
import { KolsOrderColumn } from 'types/SocialTracking/Kols';

import { AdditionalMetric } from '../ProjectView/components/widgets/Analytics/types/AdditionalMetric';

interface KeywordsFilter {
  excludeKeywords: string[];
  includeKeywords: string[];
  includeLanguages: string[];
}

export type SocialTrackingStore = {
  activity: {
    time: {
      from: string | null;
      to: string | null;
    };
    range: ActivityRange | null;
  };
  keywords: KeywordsFilter;
  setKeywords: (values: KeywordsFilter) => void;
  lastVisitedProjectId: string | null;
  setLastVisitedProjectId: (id: string | null) => void;
  isOrganicSelected: boolean;
  setIsOrganicSelected: (value: boolean) => void;
  kolsGroupsIds: string[];
  setKolsGroupsIds: (ids: string[]) => void;
  setActivityRange: (
    range: ActivityRange | null,
    from: string | null,
    to: string | null,
  ) => void;
  chartType: 'line' | 'bar' | 'area';
  setChartType: (type: 'line' | 'bar' | 'area') => void;
  selectedMetric:
    | 'Impressions'
    | 'Engagements'
    | 'CashtagMentions'
    | 'MatchingTweetsCount'
    | 'EngagementRate';
  setSelectedMetric: (
    metric:
      | 'Impressions'
      | 'Engagements'
      | 'CashtagMentions'
      | 'MatchingTweetsCount'
      | 'EngagementRate',
  ) => void;

  chartClickedActiveRange: { from: string; to: string } | null;
  setChartClickedActiveRange: (
    key: { from: string; to: string } | null,
  ) => void;
  selectedKol: string | null;
  setSelectedKol: (kol: string | null) => void;
  resetAllFilters: () => void;
  hasSeenProject: boolean;
  setHasSeenProject: (value: boolean) => void;
  isFeedShown: boolean;
  setIsFeedShown: (value: boolean) => void;
  sortingParams: {
    orderColumn: TopKolsTableParams['orderColumn'];
    orderByAscending: boolean;
  };
  setSortingParams: (value: TopKolsTableParams['orderColumn']) => void;

  kolListDropdownSort:
    | 'MetricValueDesc'
    | 'MetricValueAsc'
    | 'Name'
    | 'CreatedAt';
  setKolListDropdownSort: (
    value: 'MetricValueDesc' | 'MetricValueAsc' | 'Name' | 'CreatedAt',
  ) => void;

  includeKolsWithNoData: boolean;
  setIncludeKolsWithNoData: (value: boolean) => void;

  additionalMetrics: AdditionalMetric;
  setAdditionalMetrics: (value: AdditionalMetric) => void;
};

const prepareStore: StateCreator<SocialTrackingStore> = (set) => ({
  lastVisitedProjectId: null,
  setLastVisitedProjectId: (id) => set({ lastVisitedProjectId: id }),
  isOrganicSelected: true,
  setIsOrganicSelected: (value) => set({ isOrganicSelected: value }),
  kolsGroupsIds: [],
  setKolsGroupsIds: (ids) => set({ kolsGroupsIds: ids }),
  activity: {
    time: {
      from: dayjs().subtract(7, 'day').toISOString(),
      to: dayjs().toISOString(),
    },
    range: '7d',
  },
  setActivityRange: (range, from, to) =>
    set(() => ({
      activity: {
        time: {
          from,
          to,
        },
        range,
      },
    })),
  keywords: {
    includeKeywords: [],
    excludeKeywords: [],
    includeLanguages: [],
  },
  setKeywords: (values) => set({ keywords: values }),
  resetAllFilters: () =>
    set({
      kolsGroupsIds: [],
      isOrganicSelected: true,
      keywords: {
        includeKeywords: [],
        excludeKeywords: [],
        includeLanguages: [],
      },
      activity: {
        time: {
          from: dayjs().subtract(7, 'day').toISOString(),
          to: dayjs().toISOString(),
        },
        range: '7d',
      },
    }),
  chartClickedActiveRange: null,
  setChartClickedActiveRange: (key) =>
    set(() => ({ chartClickedActiveRange: key, selectedKol: null })),
  chartType: 'bar',
  setChartType: (type) => set(() => ({ chartType: type })),
  selectedMetric: 'MatchingTweetsCount',
  setSelectedMetric: (metric) => set(() => ({ selectedMetric: metric })),
  selectedKol: null,
  setSelectedKol: (kol) =>
    set(() => ({ selectedKol: kol, chartClickedActiveRange: null })),
  hasSeenProject: false,
  setHasSeenProject: (value) => set(() => ({ hasSeenProject: value })),
  isFeedShown: false,
  setIsFeedShown: (value) => set(() => ({ isFeedShown: value })),
  sortingParams: {
    orderByAscending: false,
    orderColumn: 'Score',
  },
  setSortingParams: (value) =>
    set((v) => ({
      sortingParams: {
        orderByAscending:
          v.sortingParams.orderColumn === value
            ? !v.sortingParams.orderByAscending
            : false,
        orderColumn: value,
      },
    })),
  kolListDropdownSort: 'MetricValueDesc',
  setKolListDropdownSort: (value) =>
    set(() => ({ kolListDropdownSort: value })),
  includeKolsWithNoData: false,
  setIncludeKolsWithNoData: (value) =>
    set(() => ({ includeKolsWithNoData: value })),
  additionalMetrics: {
    website: null,
    token: null,
    conversions: null,
    twitter: null,
  },
  setAdditionalMetrics: (value) => set(() => ({ additionalMetrics: value })),
});

export const useSocialTrackingStore = create(
  persist(prepareStore, {
    name: 'social-tracking-store',
    version: 1.0,
    partialize: (state) => ({
      activity: state.activity,
      hasSeenProject: state.hasSeenProject,
      kolsGroupsIds: state.kolsGroupsIds,
      lastVisitedProjectId: state.lastVisitedProjectId,
      chartType: state.chartType,
      selectedMetric: state.selectedMetric,
      additionalMetrics: state.additionalMetrics,
    }),
  }),
);
