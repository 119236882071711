import React from 'react';
import { PiMagnifyingGlass } from 'react-icons/pi';
import { SearchIcon } from 'lucide-react';
import { Button } from 'src/shared/ui/button';
import { Input } from 'src/shared/ui/form/input';

import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import ExplorerSearchDropdown from 'components/views/explorer/common/ExplorerSearchDropdown';

export default function TokenSearch() {
  return (
    <Popover>
      <PopoverTrigger className="flex w-full py-0">
        <Button size="xs" intent="neutral">
          Search for tokens <PiMagnifyingGlass className="" />
        </Button>
      </PopoverTrigger>

      <PopoverContent
        align="start"
        side="bottom"
        className="z-[99999999] h-max !max-h-max  w-full p-0 sm:w-[500px]"
      >
        <ExplorerSearchDropdown />
      </PopoverContent>
    </Popover>
  );
}
