import React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, VariantProps } from 'class-variance-authority';
import { format } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { cn } from 'src/utils';

const buttonVariants = cva(
  [
    'flex items-center justify-center w-max font-sans font-medium text-black antialiased',
    'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-ds-border-focus',
    'transition-all',
    'disabled:cursor-not-allowed disabled:text-ds-text-tertiary disabled:bg-ds-quarternary',
    '[&>svg]:w-[1em] [&>svg]:h-[1em]',
  ],
  {
    variants: {
      intent: {
        brand: [
          'bg-ds-brand-primary hover:bg-ds-brand-secondary group-hover:bg-ds-brand-secondary active:bg-ds-brand-tertiary ',
        ],
        brand2: [
          'bg-ds-brand2-primary hover:bg-ds-brand2-secondary group-hover:bg-ds-brand2-secondary active:bg-ds-brand2-tertiary',
        ],
        brand3: [
          'text-ds-text-onbrand3 bg-ds-brand3-primary hover:bg-ds-brand3-secondary group-hover:bg-ds-brand3-secondary active:bg-ds-brand3-tertiary',
        ],
        neutral: [
          'text-ds-button-text-default bg-ds-button-bg-default hover:bg-ds-button-bg-hover group-hover:bg-ds-button-bg-hover active:bg-ds-button-bg-pressed',
        ],
        banner: [
          'text-text-primary bg-ds-primary hover:bg-ds-secondary group-hover:bg-ds-secondary active:bg-ds-tertiary',
        ],
        inverted: [
          'text-ds-text-inverted-primary bg-ds-inverted-primary hover:bg-ds-inverted-secondary group-hover:bg-ds-inverted-secondary active:bg-ds-inverted-tertiary ',
        ],
        danger:
          'text-ds-text-inverted-primary bg-ds-red-400 hover:opacity-90 group-hover:opacity-90',
        ghost:
          'text-ds-button-text-default hover:bg-ds-button-bg-hover  grouo-hover:bg-ds-button-bg-hover active:bg-ds-button-bg-pressed',
        bordered:
          'text-ds-button-text-default bg-ds-primary border border-ds-button-bg-default hover:bg-ds-button-bg-hover group-hover:bg-ds-button-bg-hover active:bg-ds-button-bg-pressed',
      },
      size: {
        l: ['h-12 gap-2 rounded-ds_m px-5 text-lg/none'],
        m: ['h-10 gap-1.5 rounded-ds_s px-4'],
        s: ['h-8 gap-1 rounded-ds_s px-3 text-sm'],
        xs: ['h-6 gap-1.5 rounded-ds_s px-3 text-xs'],
      },
    },
    defaultVariants: {
      intent: 'brand',
      size: 'l',
    },
  },
);

const fluidButtonVariants = cva(
  [
    'group flex items-center',
    'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-ds-border-focus',
    ' group-focus-visible:!ring-0 group-focus:!ring-0 group-focus:outline group-focus:outline-2 group-focus:outline-offset-1 group-focus:outline-ds-border-focus group-focus-visible:outline group-focus-visible:outline-2 group-focus-visible:outline-offset-2 group-focus-visible:outline-ds-border-focus',
    'transition-all',
    'disabled:cursor-not-allowed disabled:text-ds-text-tertiary disabled:bg-ds-quarternary',
  ],
  {
    variants: {
      size: {
        l: ['h-[48px] text-lg'],
        m: ['h-[40px]'],
        s: ['h-[32px] text-sm'],
        xs: ['h-[24px] text-xs'],
      },
    },
    defaultVariants: {
      size: 'l',
    },
  },
);

const fluidSideButtonVariants = cva(
  [
    'group flex items-center',
    'focus-within:!ring-0 focus-visible:!ring-0 focus:!ring-0 focus:outline focus:outline-2 focus:outline-offset-1 focus:outline-ds-border-focus focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ds-border-focus',
    'group-focus-within:!ring-0 group-focus-visible:!ring-0 group-focus:!ring-0 group-focus:outline group-focus:outline-2 group-focus:outline-offset-1 group-focus:outline-ds-border-focus group-focus-visible:outline group-focus-visible:outline-2 group-focus-visible:outline-offset-2 group-focus-visible:outline-ds-border-focus',
    'transition-all',
    'disabled:cursor-not-allowed disabled:text-ds-text-tertiary disabled:bg-ds-quarternary',
  ],
  {
    variants: {
      size: {
        l: ['h-[48px] text-lg px-2'],
        m: ['h-[40px]'],
        s: ['h-[32px] text-sm'],
        xs: ['h-[24px] text-xs'],
      },
    },
    defaultVariants: {
      size: 'l',
    },
  },
);

const fluidConnectorVariants = cva(['transition-all'], {
  variants: {
    intent: {
      brand: ['fill-ds-brand-primary group-hover:fill-ds-brand-secondary'],
      brand2: ['fill-ds-brand2-primary group-hover:fill-ds-brand2-secondary'],
      brand3: ['fill-ds-brand3-primary group-hover:fill-ds-brand3-secondary'],
      neutral: [
        'fill-ds-button-bg-default group-hover:fill-ds-button-bg-hover',
      ],
      banner: ['fill-ds-primary group-hover:fill-ds-secondary'],
      inverted: [
        'fill-ds-inverted-primary group-hover:fill-ds-inverted-secondary ',
      ],
      ghost: ['fill-ds-button-bg-default group-hover:fill-ds-button-bg-hover'],
      danger: ['fill-ds-red-400 group-hover:fill-ds-red-500'],
      bordered: [
        'fill-ds-button-bg-default group-hover:fill-ds-button-bg-hover',
      ],
    },
  },
  defaultVariants: {
    intent: 'brand',
  },
});

interface ButtonBaseProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean;
  fluid?: boolean;
}

interface FluidButtonProps
  extends ButtonBaseProps,
    VariantProps<typeof buttonVariants> {
  fluid: true;
  Icon: React.ComponentType<{ size?: string | number }>;
}

interface NormalButtonProps
  extends ButtonBaseProps,
    VariantProps<typeof buttonVariants> {
  fluid?: false | undefined;
  square?: boolean;
}

export type ButtonProps = FluidButtonProps | NormalButtonProps;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, intent, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';

    if (props.fluid) {
      const { Icon, ...restProps } = props;
      return (
        <Comp
          className={cn(fluidButtonVariants({ size, className }))}
          ref={ref}
          {...restProps}
        >
          <div
            className={cn(
              'flex h-full items-center justify-center rounded-ds_s',
              buttonVariants({ intent, size, className: className }),
              {
                'px-3.5 group-hover:translate-x-5': size === 'l',
                'px-3 group-hover:translate-x-5': size === 'm',
                'px-2   group-hover:translate-x-4 group-hover:rounded-r-none':
                  size === 's',
                'px-1.5 group-hover:translate-x-4 group-hover:rounded-r-none':
                  size === 'xs',
              },
            )}
          >
            <Icon size={20} />
          </div>
          <svg
            width="6"
            height="40"
            viewBox="0 0 6 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_17820_44922)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M-186 29C-186 33.4183 -182.418 37 -178 37L-6.5 37C-2.91016 37 2.54414e-07 34.0899 5.68249e-07 30.5L8.74198e-07 27.0004C1.01906e-06 25.3433 1.34331 24 3.00035 24C4.6574 24 6.0007 25.3433 6.0007 27.0004L6.0007 30.5C6.0007 34.0899 8.91086 37 12.5007 37L42.0007 37C46.419 37 50.0007 33.4183 50.0007 29L50.0007 11C50.0007 6.58174 46.419 3.00002 42.0007 3.00002L12.5007 3.00002C8.91085 3.00002 6.0007 5.91016 6.0007 9.50001L6.0007 12.9997C6.0007 14.6567 4.6574 16 3.00035 16C1.34331 16 1.95331e-06 14.6567 2.09818e-06 12.9997L2.40413e-06 9.50001C2.71796e-06 5.91016 -2.91015 3.00002 -6.5 3.00002L-178 3C-182.418 3 -186 6.58172 -186 11L-186 29Z"
                className={fluidConnectorVariants({ intent })}
              />
            </g>
            <defs>
              <clipPath id="clip0_17820_44922">
                <rect width="6" height="40" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div
            className={cn(
              'flex h-full items-center rounded-ds_s px-3 text-ds_m font-medium',
              buttonVariants({ intent, size, className }),
            )}
          >
            {props.children}
          </div>
        </Comp>
      );
    }

    return (
      <Comp
        className={cn(
          buttonVariants({
            intent,
            size,
            className: cn(className, {
              'aspect-square px-0': props.square,
            }),
          }),
        )}
        ref={ref}
        {...props}
      />
    );
  },
);

Button.displayName = 'Button';

export { Button };
