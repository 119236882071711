import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Box, Stack } from '@mui/material';

import UnstyledLink from 'common/UnstyledLink';
import Cookie3Logo from 'theme/logo';
import { sideBarDesktopWidth } from 'utils/constants';

import SidebarContent from './Content';
import { SidebarContextProvider } from './context/useSidebarContext';

export default function NewSidebar() {
  const router = useRouter();

  const [isOpen, setIsOpen] = useState(false);
  const [isPermanentClosed, setIsPermanentClosed] = useState(false);
  const [isPermanentOpen, setIsPermanentOpen] = useState(false);

  const toggleSidebar = () => setIsOpen(!isOpen);

  const isSidebarOpen = isPermanentOpen ? true : isOpen && !isPermanentClosed;

  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // close sidebar on route change
    router.events.on('routeChangeStart', () => {
      setIsOpen(false);
    });

    return () => {
      router.events.off('routeChangeStart', () => {
        setIsOpen(false);
      });
    };
  });

  return (
    <SidebarContextProvider
      {...{
        isOpen: isSidebarOpen,
        toggleSidebar,
        isPermanentClosed: isPermanentClosed,
        transitionDuration: 150,
      }}
    >
      {/* {!isPermanentOpen && (
        <Box height="100dvh" width="60px" position="sticky" top={0} left={0} />
      )} */}
      <Box
        ref={sidebarRef}
        display={{
          xs: 'none',
          lg: 'block',
        }}
        position={'fixed'}
        overflow="hidden"
        zIndex={1000}
        left={0}
        top={0}
        className="onboarding-step-sidebar"
        height="100dvh"
        borderRight="1px solid"
        borderColor="var(--border-primary)"
        width={isSidebarOpen ? '250px' : sideBarDesktopWidth}
        sx={{
          transitionProperty: 'width',
          transitionTimingFunction: 'ease-in-out',
          transitionDuration: 100 + 'ms',
        }}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <Stack
          height="100dvh"
          top={0}
          bottom={0}
          className="bg-ds-primary"
          width="250px"
        >
          <Box pt={1} px={1.5}>
            <UnstyledLink href="/">
              <Box
                sx={{
                  overflow: 'hidden',
                  width: 140,
                  height: 'auto',
                  '& .letter': {
                    opacity: isSidebarOpen ? 1 : 0,
                    transition: `opacity ${200}ms ease-in-out`,
                  },
                }}
              >
                <Cookie3Logo />
              </Box>
            </UnstyledLink>
          </Box>

          <SidebarContent isSidebarOpen={isSidebarOpen} />
        </Stack>
      </Box>
    </SidebarContextProvider>
  );
}
